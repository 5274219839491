import React, { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import { Typography, Row, Col, message, Tag, Alert } from "antd";
import { getMe } from "../../services/user.service.js";
import { listAppointments } from "../../services/appointment.service.js";
import { BiometricForm } from "../biometricForm/biometricForm.component.js";
import { PatientProfile } from "../patientProfile/patientProfile.component.js";
import { BiometricStatusTag } from "../appointmentStatusTag/appointmentStatusTag.component.js";
import Panel from "../../enums/panel.enum.js";
import { MessageOutlined, EnvironmentOutlined, TagsOutlined, FileTextOutlined, InfoCircleOutlined} from '@ant-design/icons';
import { PDFDocument } from 'pdf-lib';
import { getRequisitionFileLink } from "../../services/order.service"; 
import PhlebType  from "../../enums/phlebType.enum.js";
import AppointmentStatus from "../../enums/appointmentStatus.enum.js";
import "./style.scss";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { SwipeAction } from 'antd-mobile';
import { Modal } from 'antd-mobile';

dayjs.extend(utc);
dayjs.extend(timezone);

const { Text } = Typography;
const DAYS = 120;

export const Appointments = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dates, setDates] = useState([]);
  const [timeZoneId, setTimeZoneId] = useState();
  const [currentTime, setCurrentTime] = useState(dayjs());
  const [isBiometricsVisible, setIsBiometricsVisible] = useState(false);
  const [isProfileVisible, setIsProfileVisible] = useState(false);
  const [appointment, setAppointment] = useState();
  const [patient, setPatient] = useState();
  const swipeActionRef = useRef([]);
  const [phlebotomist, setPhlebotomist] = useState();

  useEffect(() => {
    fetchPhlebotomist();
  }, []);

  useEffect(() => {
    if (phlebotomist) {
      const timezone = phlebotomist.location?.timeZoneId ? phlebotomist.location?.timeZoneId : 'America/Los_Angeles';
      dayjs.tz.setDefault(timezone);
      setTimeZoneId(timezone);
    }
  }, [phlebotomist]);

  useEffect(() => {
    if (phlebotomist && timeZoneId) 
      fetchDates();
  }, [phlebotomist, timeZoneId]);

  const fetchPhlebotomist = async () => {
    setPhlebotomist(await getMe());
  };

  const fetchDates = async () => {

    const params = {
      populate: [
        {
          path: 'patients',
          select: '_id firstName lastName phone location dob'
        },
        {
          path: 'orders',
          populate: [{
              path: 'lab',
              select: '_id abbreviation name'
            },
            {
              path: 'tests',
              select: '_id name'
            },
            {
              path: 'panels',
              select: '_id name'
            }
          ]
        }
      ],
      filter: {
        start: {
          $gte: dayjs().subtract(2, "day").toDate(),
          $lte: dayjs().add(30, "day").toDate(),
        },
        status: { $in: [AppointmentStatus.CONFIRMED, AppointmentStatus.COLLECTED, AppointmentStatus.COMPLETED] },
        phlebType: { $in: [PhlebType.INSTALAB, PhlebType.GETLABS] }
      },
      sort: '-start'
    }

    let appointments = await listAppointments(params);


    appointments = appointments.filter(({ patients }) => patients?.length)

    const updatedAppointments = await Promise.all(
      appointments.map(async (appointment) => {
        const updatedOrders = appointment.orders?.length ? await Promise.all(
          appointment.orders.map(async (order) => {
            if (order.reqFileName) {
              order.reqFileLink = await getRequisitionFileLink(order.reqFileName);
            }
            return order;
          })
        ) : []
        appointment.orders = updatedOrders;
        return appointment;
      })
    );

    setDates(
      Array.from(Array(DAYS))
        .map((_, i) => {
          const date = dayjs().subtract(3, "day").add(i, "days");
          return {
            date,
            appointments: updatedAppointments
              .filter(({ start }) => {
                const startDay = dayjs(date).tz(timeZoneId).startOf("day");
                const endDay = dayjs(date).tz(timeZoneId).endOf("day");
                return dayjs(start) > startDay && dayjs(start) < endDay;
              })
              .sort((a, b) => {
                const aStart = dayjs(a.start).tz(timeZoneId).toDate();
                const bStart = dayjs(b.start).tz(timeZoneId).toDate();
                return aStart - bStart;
              }),
          };
        })
        .filter((appointmentsByDate) => {
          return appointmentsByDate.appointments.length;
        })
    );
    setIsLoading(false);
  };

  const openMap = (appointment) => {
    if (!appointment.location)
      appointment.location = appointment.patients[0].location;

    const { streetAddress, city, state, postalCode } = appointment.location;
    const location = `${streetAddress}, ${city}, ${state} ${postalCode}`;
    const url = `http://maps.google.com/?daddr=${location.replace(/\s/g, "+")}`;
    window.open(url, '_blank');
    message.info("Google maps opened");
  };

  const openBiometrics = (appointment, patient) => {
    setIsBiometricsVisible(true);
    setAppointment(appointment);
    setPatient(patient);
  };

  const openProfile = (appointment) => {
    setIsProfileVisible(true);
    setAppointment(appointment);
  };

  const contactPatient = (appointment) => {
    const phones = appointment.patients
      .map(({ phone }) => phone.replace(/\D/g, ""))
      .join(",");
    const isAndroid = navigator.userAgent.toLowerCase().includes("android");
    window.open(isAndroid ? `sms:${phones}` : `sms:/open?addresses=${phones}`);
  };

  const openLabel = async (appointment) => {
    const orders = appointment.orders.map((order) => order._id).join(",");
    window.open(`/labels?orderIds=${orders}`, "_blank");
  };

  async function mergePDFs(pdfUrls) {
    const pdfDoc = await PDFDocument.create();
  
    for (let url of pdfUrls) {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`Failed to fetch PDF from ${url}`);
        }
        const arrayBuffer = await response.arrayBuffer();
        const pdf = await PDFDocument.load(arrayBuffer);
        const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());
        copiedPages.forEach(page => pdfDoc.addPage(page));
      } catch (error) {
        console.error(`Error fetching or processing PDF from ${url}:`, error);
        message.error(`Error fetching or processing PDF from ${url}`);
      }
    }
  
    const mergedPdfArrayBuffer = await pdfDoc.save();
    return new Blob([mergedPdfArrayBuffer], { type: "application/pdf" });
  }
  
  const openReqLink = async (appointment) => {
    const pdfUrls = appointment.orders.map(order => order.reqFileLink).filter(url => url);
    if (pdfUrls.length === 0) {
      message.error("No requisition files available");
      return;
    }
  
    if (pdfUrls.length === 1) {
      window.open(pdfUrls[0], '_blank');
    } else {
      try {
        const mergedPdfBlob = await mergePDFs(pdfUrls);
        if (mergedPdfBlob) {
          const pdfUrl = URL.createObjectURL(mergedPdfBlob);
          const newWindow = window.open(pdfUrl, '_blank');
          if (!newWindow) {
            console.error('Failed to open new window for merged PDF');
            alert('Popup blocked. Please allow popups for this site.');
          }
        }
      } catch (error) {
        console.error('Error merging PDFs:', error);
        message.error("Failed to merge PDFs");
      }
    }
  };
  

  // const Action = {
  //   PROFILE: "Info",
  //   LABEL: "Labels",
  //   REQ: "Reqs",
  //   MAPS: "Map",
  //   CONTACT: <><MessageOutlined style={{fontSize: '20px'}} /></>,
  // };

  const getTubes = (panel, lab) => {
    const tubesByPanelAndLab = {
      [Panel.ADVANCED_FOLLOWUP]: {
        'Solaris': {
          'SST': 2,
          'Lavender': 2,
          'Pour Off (Citrated)': 1
        },
        'Empire': {
          'SST': 3,
          'Black/Yellow': 1,
          'Lavender': 1,
        }
      },
      [Panel.ATHLETE]: {
        'Solaris': {
          'SST': 4,
          'Royal Blue': 2,
          'Lavender': 1,
          'Pour Off (Citrated)': 1
        },
        'Empire': {
          'SST': 3,
          'Black/Yellow': 2,
          'Royal Blue Purple': 2,
          'Royal Blue Red': 1,
          'Lavender': 2,
        }
      },
    };

    // First try to get lab-specific tubes, fallback to Solaris if not found
    return tubesByPanelAndLab[panel]?.[lab?.abbreviation] || tubesByPanelAndLab[panel]?.['Solaris'];
  };

  const renderAppointmentActions = (appointment) => {
    const rightActions = [
      {
        key: 'profile',
        text: (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px' }}>
            <InfoCircleOutlined style={{ fontSize: '20px' }} />
            <span>Info</span>
          </div>
        ),
        color: '#722ed1',
        onClick: () => openProfile(appointment)
      },
      {
        key: 'map',
        text: (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px' }}>
            <EnvironmentOutlined style={{ fontSize: '20px' }} />
            <span>Map</span>
          </div>
        ),
        color: '#87d068',
        onClick: () => openMap(appointment)
      },
      {
        key: 'text',
        text: (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px' }}>
            <MessageOutlined style={{ fontSize: '20px' }} />
            <span>Text</span>
          </div>
        ),
        color: '#1677ff',
        onClick: () => contactPatient(appointment)
      },
      {
        key: 'labels',
        text: (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px' }}>
            <TagsOutlined style={{ fontSize: '20px' }} />
            <span>Labels</span>
          </div>
        ),
        color: '#ff9800',
        onClick: () => openLabel(appointment)
      },
      {
        key: 'reqs',
        text: (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '4px' }}>
            <FileTextOutlined style={{ fontSize: '20px' }} />
            <span>Reqs</span>
          </div>
        ),
        color: '#f50',
        onClick: () => openReqLink(appointment)
      }
    ];

    return (
      <SwipeAction rightActions={rightActions}>
        <div className="swipeable-content">
          <Row gutter={[8, 8]}>
            <Col xs={24}>
              <Text strong className="time">
                {dayjs(appointment.start).tz(timeZoneId).format("h:mma")}
              </Text>
              <div 
                className="appointment-address"
                onClick={(e) => {
                  e.stopPropagation();
                  openMap(appointment);
                }}
              >
                <EnvironmentOutlined />
                {appointment.location ? 
                  `${appointment.location.streetAddress}${appointment.location.streetAddress2 ? `, ${appointment.location.streetAddress2}` : ''}, ${appointment.location.city}, ${appointment.location.state} ${appointment.location.postalCode}` : 
                  (appointment.patients[0]?.location ? 
                    `${appointment.patients[0].location.streetAddress}${appointment.patients[0].location.streetAddress2 ? `, ${appointment.patients[0].location.streetAddress2}` : ''}, ${appointment.patients[0].location.city}, ${appointment.patients[0].location.state} ${appointment.patients[0].location.postalCode}` :
                    'No address available'
                  )
                }
              </div>
            </Col>

            <Col xs={24}>
              {appointment.patients.map((patient) => {
                let orders = appointment.orders.filter((order) => order.patient === patient._id);
                if (!orders) return null;
                
                return (
                  <div
                    key={patient._id}
                    className="appointment-patient"
                    onClick={() => openBiometrics(appointment, patient)}
                  >
                    <div className="patient-name-container">
                      <Text strong className="patient-name">
                        {patient.firstName} {patient.lastName}
                      </Text>
                      <BiometricStatusTag
                        appointment={appointment}
                        patient={patient}
                      />
                    </div>

                    {orders.map((order, index) => (
                      <div className="panel-item" key={index}>
                        <div className="panel-item-lab">
                         {order.lab ? order.lab?.name : 'TBD lab'}
                        </div>    
                        
                        {getTubes(order.panel, order.lab) ? (
                          <div className="tube-container">
                            {Object.keys(getTubes(order.panel, order.lab)).map(tubeType => (
                              <Tag 
                                className="tube-item"
                                color="#f5f5f5"
                                key={`tube-${tubeType}-${order._id}`}
                              >
                                <b>{getTubes(order.panel, order.lab)[tubeType]}</b> {tubeType}
                              </Tag>
                            ))}
                          </div>
                        ) : (
                          <Text type="secondary">See Requisition for Tubes</Text>
                        )}
                      </div>
                    ))}

                    {appointment.notes && (
                      <Alert 
                        message={appointment.notes} 
                        type="info" 
                        className="appointment-notes"
                      />
                    )}

                    {!patient.phone && (
                      <Alert
                        message="This patient doesn't have US phone number."
                        type="warning"
                        style={{ marginTop: '8px' }}
                      />
                    )}
                  </div>
                );
              })}
            </Col>
          </Row>
        </div>
      </SwipeAction>
    );
  };

  return !isLoading && phlebotomist && timeZoneId && dates?.length > 0 ? (
    <div className="appointments-component">
      {dates?.map(({ date, appointments: appointmentsByDate }, index) => (
        <div key={date} className={`date-${index}`}>
          <div className="page-header">
            <Text strong style={{ fontSize: '18px' }}>
              {date
                .tz(timeZoneId)
                .format("dddd MMM D, YYYY")}

              {date.isSame(currentTime, "day") && (
                <Tag color="primary" className="today-tag" style={{ marginLeft: '8px' }}>
                  Today
                </Tag>
              )}
            </Text>
          </div>

          {appointmentsByDate.map((appointment) => (
            <div key={appointment._id} className="appointment-card">
              {renderAppointmentActions(appointment)}
            </div>
          ))}
        </div>
      ))}

      <Modal
        visible={isBiometricsVisible}
        closeOnMaskClick={true}
        onClose={() => {
          setIsBiometricsVisible(false);
          setAppointment(null);
          setPatient(null);
        }}
        content={
          <div className="biometric-modal-content">
            <div className="biometric-form-header">
              <Text>
                Biometrics for <span className="patient-name">{patient?.firstName} {patient?.lastName}</span>
              </Text>
            </div>

            <div className="biometric-form-scroll-container">
              <BiometricForm
                appointment={appointment}
                patient={patient}
                setAppointment={setAppointment}
                visible={isBiometricsVisible}
                setVisible={setIsBiometricsVisible}
                dates={dates}
                setDates={setDates}
              />
            </div>
          </div>
        }
      />

      <PatientProfile
        visible={isProfileVisible}
        setVisible={setIsProfileVisible}
        appointment={appointment}
      />
    </div>
  ) : null;
};
