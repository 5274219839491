import React, { useState, useEffect } from 'react'
import { Descriptions, Typography, Tag } from 'antd'
import { Modal, Button } from 'antd-mobile';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import './style.scss'

dayjs.extend(utc)
dayjs.extend(timezone)

const { Text } = Typography
const { Item } = Descriptions

const AppointmentDetails = ({ appointment, location, getEntryInstructions }) => (
  <Descriptions title="Appointment Details" bordered>
    <Item label="Time">
      {dayjs(appointment.start)
        .tz(appointment.location.timeZoneId)
        .format('MMM D, YYYY @ h:mma')}
    </Item>
    <Item label="Address">{location}</Item>
    {getEntryInstructions() && (
      <Item label="Entry">{getEntryInstructions()}</Item>
    )}
    {appointment.notes && <Item label="Notes">{appointment.notes}</Item>}
  </Descriptions>
);

const PatientDetails = ({ patient, orders, index, totalPatients }) => (
  <Descriptions 
    title={`Patient Information${totalPatients > 1 ? ` (${index + 1}/${totalPatients})` : ''}`} 
    bordered
  >
    <Item label="Name">
      {patient.firstName} {patient.lastName}
    </Item>
    <Item label="DOB">{patient.dob}</Item>
    <Item label="Phone">{patient.phone}</Item>
    {orders.map((order, index) => (
      <Item key={index} label="Order">
        {order.panels.length > 0 && (
          <div className="order-info-item">
            Panels: {order.panels.map(panel => (
              <Tag key={panel.name} color="default">{panel.name}</Tag>
            ))}
          </div>
        )}
        {order.tests.length > 0 && (
          <div className="order-info-item">
            Individual Tests: {order.tests.map(test => (
              <Tag key={test.name} color="default">{test.name}</Tag>
            ))}
          </div>
        )}
        <div className="order-info-item">
          Lab: {order.lab?.name || 'TBD'}
        </div>
      </Item>
    ))}
  </Descriptions>
);

const ModalContent = ({ appointment, location, onClose, getEntryInstructions }) => (
  <>
    <div className="patient-profile-header">
      <Text>Appointment Info</Text>
    </div>

    <div className="patient-profile-content">
      <AppointmentDetails 
        appointment={appointment} 
        location={location}
        getEntryInstructions={getEntryInstructions}
      />

      {appointment.patients
        .filter(patient => 
          appointment.orders.some(order => order.patient === patient._id)
        )
        .map((patient, index, filteredPatients) => (
          <PatientDetails
            key={patient._id}
            patient={patient}
            orders={appointment.orders.filter(
              order => order.patient === patient._id
            )}
            index={index}
            totalPatients={filteredPatients.length}
          />
        ))}
    </div>

    <div className="modal-footer">
      <Button className="cancel-btn" onClick={onClose}>
        Cancel
      </Button>
    </div>
  </>
);

export const PatientProfile = ({ appointment, visible, setVisible }) => {
  const [location, setLocation] = useState()

  useEffect(() => {
    if (appointment) {
      setLocation(getLocation())
    }
  }, [appointment])


  const getLocation = () => {
    let location = appointment.location
    if (!location) location = appointment.patients[0].location
    const {
      streetAddress,
      streetAddress2,
      city,
      state,
      postalCode,

    } = location
    let address = streetAddress
    if (streetAddress2) address += ` ${streetAddress2}`
    return `${address}, ${city}, ${state} ${postalCode}`
  }

  const getEntryInstructions = () => {
    let location = appointment.location
    if (!location) location = appointment.patients[0].location
    return location.entryInstructions
  }

  if (!appointment) return null;

  return (
    <div className="patient-profile-component">
      <Modal
        visible={visible}
        closeOnAction
        onClose={() => setVisible(false)}
        closeOnMaskClick
        maskClassName="patient-profile-modal"
        content={
          <ModalContent
            appointment={appointment}
            location={location}
            onClose={() => setVisible(false)}
            getEntryInstructions={getEntryInstructions}
          />
        }
      />
    </div>
  );
}